import React from "react";
import "./Footer.css";
import brandOne from "../../assets/icons/colacola.png";
import brandTwo from "../../assets/icons/adio-vector-logo.png";
import brandThree from "../../assets/icons/truper-vector-logo.png";
function Footer() {
  return (
    <div className="footer-container">
      <div className="brands">
        <img src={brandOne} />
        <img src={brandThree} />
        <img src={brandTwo} />

        <img src={brandOne} />
        <img src={brandThree} />
      </div>
      <div className="bottom-info">
        <div>
          <h1>Lucas.</h1>
          <p>Apparently we had reached a great height in the atmosphere</p>
        </div>
        <div>
          <h2>CONTACT</h2>
          <p className="li">Email:education@edu.com</p>
          <p className="li">Phone:+(254) 12345678</p>
        </div>
        <div>
          <h2>Subscribe</h2>
          <div className="email-hold">
            <input placeholder="Enter Email" className="email-input" />
            <button className="submit">Submit</button>
          </div>
        </div>
      </div>
      <hr />
      <div className="end">
        <p>Privacy & Policy</p>
        <p>Privacy Policy terms & conditions</p>
        <p>&copy; 2022 Billy</p>
      </div>
    </div>
  );
}

export default Footer;
