import React from "react";
import "./Admin.css";
import VirtualImg from "../../assets/images/pexels-ekaterina-bolovtsova-4049991.jpg";
import vImg from "../../assets/images/pexels-andrea-piacquadio-3769021.jpg";
import artImg from "../../assets//icons/png-clipart-pie-chart-computer-icons-diagram-scalable-graphics-circular-graph-infographic-orange-thumbnail.png";

import { motion } from "framer-motion";
const transition = { type: "spring", duration: 1 };
function Admin() {
  return (
    <div className="admin-container">
      <div className=" blur two" />
      <div className="admin-left">
        <p className="p">Administrative in nature</p>
        <h2>Online assistance to support your success at EPCC.</h2>
        <h1>$23,45.00</h1>
        <p className="main-ad-text">
          A virtual assistant is an independent contractor who supports <br />
          clients from a remote location their most
        </p>
      </div>
      <div className="admin-right">
        <motion.div drag className="blur" />
        <div className="circle-art"></div>
        <motion.div
          className="startup"
          whileInView={{ left: "4rem" }}
          transition={{ ...transition, type: "tween" }}
        >
          <img src={artImg} alt="" />

          <p>80% startups</p>
          <p>Investors</p>
        </motion.div>
        <div className="box-two">
          <img src={VirtualImg} />
          <h1>Masudh Klinton</h1>
          <p>Transfer amount</p>
          <h2>$1400.56</h2>
        </div>
        <div className="card">
          <div>
            <img src={vImg} />
            <div>
              <h1>Billy Kibet</h1>
              <p>Managing director</p>
            </div>
          </div>
          <p>
            Assistance online offers various tools to help you. To open a new
            ticket, click on support technical
          </p>
        </div>
      </div>
    </div>
  );
}

export default Admin;
