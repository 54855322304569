import React from "react";
import "./Virtual.css";
import virtualImg from "../../assets/images/pexels-pixabay-207582.jpg";

import dataIc from "../../assets/icons/pillar-outline-icon.svg";
import boostIc from "../../assets/icons/real-time-icon.svg";
function Virtual() {
  return (
    <div className="virtual-container">
      <div className="virtual-left">
        <div className="outer-virtual-container">
          <img src={virtualImg} alt="" />
        </div>
      </div>
      <div className="virtual-hero-right">
        <div className="virtual-right">
          <h1>25,400+</h1>
          <h2>
            A virtual assistant is an independent contractor who supports
            clients from a remote location
          </h2>
          <div>
            <div className="virtual-item">
              <div>
                <img src={dataIc} alt="" />
                <h3>Data checking</h3>
              </div>
              <p>
                Meet your google assistant. Ask it questions. Tell it to do a
                thing . It is your own personal google always ready to help
              </p>
            </div>
            <div className="virtual-item">
              <div>
                <img src={boostIc} alt="" />
                <h3>Boost Performance</h3>
              </div>
              <p>
                Assistance online offers various tools to assist you to open a
                technical assistance in your console
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Virtual;
