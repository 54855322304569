import React from "react";
import "./Hero.css";
// Images Import
import { motion } from "framer-motion";
import playIc from "../../assets/icons/BK - Play IC.svg";
import heroImg from "../../assets/images/pexels-jc-laurio-1288171.jpg";
// -->> Images Import
function Hero() {
  return (
    <div className="hero-container">
      <div className="blur" />
      <div className="hero-left">
        <p className="sp-text">20 Cities and growing</p>
        <h1 className="hero-title">
          Being founders Takes guts <img src="" alt="" />
        </h1>
        <p className="hero-text">
          Knowing your risk tolerance can serve as an internal compass guiding
          your business growth decisions
        </p>
        <div className="hero-btn">
          <button>Book Demo</button>
          <button className="sp-btn">
            <motion.img
              animate={{ rotate: 360 }}
              whileHover={{ rotate: 0 }}
              transition={{ duration: 0.3 }}
              src={playIc}
              alt=""
            />
            Watch Showreel
          </button>
        </div>
      </div>
      <div className="hero-right">
        <div className="outer-border">
          <img src={heroImg} alt="" />
        </div>
        <h1>Lucas.</h1>
      </div>
    </div>
  );
}

export default Hero;
