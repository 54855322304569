import "./App.css";
import Admin from "./components/Admin/Admin";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Testimonials from "./components/Testimonials/Testimonials";
import Trend from "./components/Trend/Trend";
import Virtual from "./components/Virtual/Virtual";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <div className="all" />
      <Navbar />
      <Hero />
      <Trend />
      <Virtual />
      <Admin />

      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
