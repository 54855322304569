import React from "react";
import "./Trend.css";
import { motion } from "framer-motion";
// Images Import
import icOne from "../../assets/icons/rocket-launch-line-icon.svg";
import icTwo from "../../assets/icons/question-mark-circle-outline-icon.svg";
import authorImg from "../../assets/images/pexels-jc-laurio-1288171.jpg";
// -->> Images Import
function Trend() {
  return (
    <motion.div
      animate={{ x: [1000, 0] }}
      transition={{ duration: 1, type: "spring", bounce: 5 }}
      className="trend-container"
    >
      <div>
        <h1 className="title">Bussiness trends</h1>
        <p>Business is the activity of making one's living or making money</p>
        <div className="author">
          <img src={authorImg} />
          <div>
            <h1 className="authorname">Thomas Speark</h1>
            <h2 className="occupation">Managing Partner</h2>
          </div>
        </div>
      </div>

      <div className="trend-box">
        <div>
          <img src={icOne} alt="" />
          <p>May 2021</p>
        </div>
        <h1>Visibility Booster</h1>
        <p>Unique visibility pointers designed and sold by artist</p>
      </div>
      <div className="trend-box">
        <div>
          <img src={icTwo} alt="" />
          <p>May 2021</p>
        </div>
        <h1>Online Assistance</h1>
        <p>
          A virtual assistant is an independent contractor who supports clients
        </p>
      </div>
    </motion.div>
  );
}

export default Trend;
