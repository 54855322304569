import React from "react";
import "./Navbar.css";
import { motion } from "framer-motion";
function Navbar() {
  return (
    <div className="navbar-container">
      <h1>Lucas</h1>

      <motion.div
        animate={{ scale: [1, 1.1, 1.2, 1.1, 1] }}
        transition={{ type: "spring", bounce: 5, duration: 1 }}
        className="navbar-links"
      >
        <a href="">Portfolio</a>
        <a href="">About us</a>
        <a href="">Join us</a>
        <a href="">News & Events</a>
      </motion.div>
      <button>Register</button>
    </div>
  );
}

export default Navbar;
