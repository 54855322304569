import React from "react";
import "./Testimonials.css";
import virtualImg from "../../assets/images/pexels-andrea-piacquadio-3777948.jpg";
import v2 from "../../assets/images/pexels-christina-morillo-1181474.jpg";
import v3 from "../../assets/images/pexels-andrea-piacquadio-3786525.jpg";

function Testimonials() {
  return (
    <div className="testimonial-container">
      <p>Client testimonials</p>
      <h1>
        Set the optional path to <br />
        achieve them
      </h1>

      <div className="testimonial-holder">
        <div className="testimonial-item">
          <div>
            <img src={v3} alt="" />
            <div>
              <h1>Masudh Klinton</h1>
              <h2>Managing Director</h2>
            </div>
          </div>
          <p>
            Assistance. Online offers various tools to assist you. To open a new
            ticket, Click on support technical
          </p>
        </div>
        <div className="testimonial-item">
          <div>
            <img src={virtualImg} alt="" />
            <div>
              <h1>Masudh Klinton</h1>
              <h2>Managing Director</h2>
            </div>
          </div>
          <p>
            Assistance. Online offers various tools to assist you. To open a new
            ticket, Click on support technical
          </p>
        </div>{" "}
        <div className="testimonial-item">
          <div>
            <img src={v2} alt="" />
            <div>
              <h1>Masudh Klinton</h1>
              <h2>Managing Director</h2>
            </div>
          </div>
          <p>
            Assistance. Online offers various tools to assist you. To open a new
            ticket, Click on support technical
          </p>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
